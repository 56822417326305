import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { colors, styles } from 'herald-fe-shared';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { Container } from '~/layout/Layout';
import Layout from '~/layout/LayoutMarketing';
import LargeCTA from '../components/LargeCTA';
import Section from '~/components/Section';
import Animation from '~/illustrations/HowItWorksAnimated';

const Styled = styled.div`
  /* OPENER */
  .section--opener {
    margin-top: -4rem;
    padding-top: 6rem;
    padding-bottom: 5rem;
    background: ${colors.BLUE_LIGHT(0.1)};
    height: 25rem;
  }
  .section--opener .container {
    position: relative;
    max-width: 60rem;
  }
  .section--opener .container__text {
    position: absolute;
    border-radius: 3px;
    width: fit-content;
    margin: auto;
    top: 14rem;
    left: 0;
    padding: 1rem;
    z-index: 7;
    text-align: center;
  }
  .section--opener .container__image {
    margin-top: -9rem;
    width: 60rem;
    margin-left: calc(100% - 40rem);
  }
  .section--opener .container__image svg {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 500px) {
    .section--opener .container__image {
      display: none;
    }
    .section--opener .container__text {
      position: initial;
      margin-top: 5rem;
    }
  }

  .content {
    padding: 2rem;
    margin: auto;
    max-width: 60rem;
    p {
      font-size: 1.2rem;
      line-height: 2rem;
      margin-bottom: 1.2rem;
      font-family: adobe-caslon-pro, Georgia, 'Times New Roman', Times, serif;
      color: ${colors.GRAY_4()};
    }
    a {
      font-family: adobe-caslon-pro, Georgia, 'Times New Roman', Times, serif;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 1rem 0;
      color: ${colors.GRAY_4()};
    }
    h1 {
      font-size: 2rem;
      line-height: 2.2rem;
      margin-top: 3rem;
      &:first-child {
        margin-top: 0;
      }
    }
    h2 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    h3 {
      font-size: 1rem;
      line-height: 1.2rem;
    }
    h4 {
      font-size: 0.9rem;
      line-height: 1.1rem;
    }
    h5 {
      font-size: 0.8rem;
      line-height: 1rem;
    }
    h6 {
      margin-top: 0;
      font-size: 1.2rem;
      line-height: 1.7rem;
      font-weight: normal;
      color: ${colors.PURPLE_LIGHT()};
      padding: 1.5rem;
      background: ${colors.PURPLE_LIGHT(0.1)};
      border-left: 3px solid ${colors.PURPLE_LIGHT()};
    }
    ul {
      display: initial;
    }
    li {
      list-style-type: disc;
      margin-left: 1rem;
    }
    li p {
      margin-bottom: 0.5rem;
    }
    blockquote {
      margin: 3rem 0;
      border-top: 1px solid ${colors.PURPLE_DARK()};
      border-bottom: 1px solid ${colors.PURPLE_DARK()};
      background: ${colors.PURPLE_DARK(0.02)};
      padding: 2rem;
    }
    blockquote p {
      font-size: 1.5rem;
      line-height: 2.25rem;
      color: ${colors.PURPLE_DARK()};
      margin: 0;
      text-align: center;
      font-style: italic;
    }
    figure {
      margin: 2rem 0;
      width: 100%;
      background: ${colors.GRAY_2(0.5)};
      padding: 2rem;
      display: flex;
      flex-flow: column;
      border-radius: ${styles.BORDER_RADIUS};
    }
    figure img,
    figure iframe {
      width: 100%;
      margin-bottom: 2rem;
      box-shadow: ${styles.BOX_SHADOW};
    }
    figure figcaption {
      width: 100%;
      color: ${colors.GRAY_3()};
      font-size: 1rem;
      text-align: center;
    }
    iframe {
      border: none;
      height: 28rem;
    }
    @media screen and (max-width: 800px) {
      h1 {
        font-size: 2.5rem;
        line-height: 3rem;
      }
      h2 {
        font-size: 2rem;
        line-height: 2.5rem;
      }
      h3 {
        font-size: 1.75rem;
        line-height: 2rem;
      }
      h4,
      h5 {
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
      h6 {
        font-size: 1.5rem;
        line-height: 2.5rem;
      }
      blockquote p {
        font-size: 2rem;
        line-height: 3rem;
      }
      p,
      li {
        font-size: 1.75rem;
        line-height: 2.5rem;
        margin-bottom: 1rem;
      }
      li {
        margin-bottom: 0rem;
        margin-left: 2rem;
      }
      figure {
        margin: 2rem -2rem;
        width: 100vw;
        padding: 2rem 1rem;
      }
    }
  }
`;

const renderOptions = {
  renderNode: {
    'embedded-asset-block': (node: any) => {
      let obj = null;
      if (node.data.target.fields) {
        obj = node.data.target.fields;
      }
      return obj ? (
        <figure>
          <a href={obj.file['en-US'].url} target="_blank">
            <img src={obj.file['en-US'].url} />
          </a>
          <figcaption>{obj.description['en-US']}</figcaption>
        </figure>
      ) : null;
    },
    'embedded-entry-block': (node: any) => {
      const obj = node.data.target.fields || null;
      return obj ? (
        <figure>
          <iframe src={obj.url['en-US']}></iframe>
          <figcaption>{obj.caption['en-US']}</figcaption>
        </figure>
      ) : null;
    },
  },
};

interface IHowItWorksData {
  contentfulPage: {
    title: string;
    description: { description: string };
    content: IRichText;
  };
}

const HowItWorks: React.FC<{ data: IHowItWorksData }> = (props) => {
  const page = props.data.contentfulPage;
  const content = page.content.json;

  return (
    <Layout title={page.title} description={page.description.description}>
      <Styled>
        <Section className="section--opener">
          <Container className="container">
            <div className="container__text">
              <h2 className="text-accent">How Herald Works</h2>
            </div>
            <div className="container__image">
              <Animation />
            </div>
          </Container>
        </Section>
        <div className="content">
          {documentToReactComponents(content, renderOptions)}
        </div>
      </Styled>
      <LargeCTA />
    </Layout>
  );
};

export const query = graphql`
  query HowItWorksPage {
    contentfulPage(slug: { eq: "how-it-works" }) {
      title
      description {
        description
      }
      content {
        json
      }
    }
  }
`;

export default HowItWorks;
